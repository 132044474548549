import { createSlice } from '@reduxjs/toolkit';

const initialQuoteState = {
    quoteLoading: false,
    quote_summary: null,
    hasQuote: false,
    rate_code: null,
};

const quoteSlice = createSlice({
    name: 'quote',
    initialState: initialQuoteState,

    reducers: {
        setQuoteLoading(state) {
            state.quoteLoading = true;
        },

        setQuoteStopLoading(state) {
            state.quoteLoading = false;
        },
        setQuoteSummary(state, action) {
            state.quote_summary = action.payload;
        },
        setQuoteRateCode(state, action) {
            state.rate_code = action.payload;
        },
        setHasQuote(state) {
            state.hasQuote = true;
        },
    },
});

export const quoteActions = quoteSlice.actions;
export default quoteSlice.reducer;
