import { createSlice } from '@reduxjs/toolkit';

const initialThemeState = {
    headerColour: '#21ba45',
    buttonColour: '#21ba45',
    labelColour: '#21ba45',
    menuFontColour: '#000000',
    menuFontWeight: 'normal',
    filterLabelColour: '#21ba45',
    headerFontColour: '#000000',
    menuFontSize: '1rem',
};

// Utility
const checkValidHex = hexCode => {
    return /^#[0-9A-F]{6}$/i.test(hexCode);
};

const themeSlice = createSlice({
    name: 'theme',
    initialState: initialThemeState,

    reducers: {
        setMenuFontSize(state, action) {
            state.menuFontSize = action.payload;
        },

        setHeaderColour(state, action) {
            if (action.payload !== null) {
                if (checkValidHex(action.payload)) {
                    state.headerColour = action.payload;
                } else {
                    console.log(action.payload);

                    console.error('Hex value is invalid for Header Colour');
                }
            }
        },
        setButtonColour(state, action) {
            if (action.payload !== null) {
                if (checkValidHex(action.payload)) {
                    state.buttonColour = action.payload;
                } else {
                    console.error('Hex value is invalid for Button Colour');
                }
            }
        },
        setLabelColour(state, action) {
            if (action.payload !== null) {
                if (checkValidHex(action.payload)) {
                    state.labelColour = action.payload;
                } else {
                    console.error('Hex value is invalid for Button Colour');
                }
            }
        },
        setFilterLabelColour(state, action) {
            if (action.payload !== null) {
                if (checkValidHex(action.payload)) {
                    state.filterLabelColour = action.payload;
                } else {
                    console.error('Hex value is invalid for Button Colour');
                }
            }
        },
        setMenuFontColour(state, action) {
            if (action.payload !== null) {
                if (checkValidHex(action.payload)) {
                    state.menuFontColour = action.payload;
                } else {
                    console.error('Hex value is invalid for Button Colour');
                }
            }
        },
        setMenuFontWeight(state, action) {
            if (action.payload !== null) {
                state.menuFontWeight = action.payload;
            }
        },
        setHeaderFontColour(state, action) {
            if (action.payload !== null) {
                if (checkValidHex(action.payload)) {
                    state.headerFontColour = action.payload;
                } else {
                    console.error('Hex value is invalid for Button Colour');
                }
            }
        },
    },
});

export const themeActions = themeSlice.actions;
export default themeSlice.reducer;
