import { createSlice } from '@reduxjs/toolkit';

const initialCounterState = {
    showOption: false,
    showExtra: true,
    showHospital: true,
    showSubMotivation: true,
    zoho_number: null,
    queryForm: {
        medicareEligibility: null,
        has_current_fund: null,
        motivation: null,
        subMotivation: null,
        membership: null,
        coverType: null,
        state: null,
        excess: null,
        currentFund: null,
        currentFundMembership: null,
        currentFundState: null,
        currentFundPolicy: null,
        currentFundExtraPolicy: null,
        currentPremium: null,
        primaryService: null,
        preferredExcess: null,
        hospitalProducts: [],
        ancillaryProducts: [],
        hasHoldInsurance: null,
        lhcAge: null,
        lhcAgePartner: null,
        applyRebate: null,
        rebate: null,
        priceFilter: 'price_lowhi',
        dob: null,
        partnerDob: null,
        youthDiscount: null,
        partnerYouthDiscount: null,
        discount: null,
    },
    query: null,
    hospitalTiers: [],
    funds: [],
    formPolicies: [],
    formExtraPolicies: [],
    corporateForm: [],
    requireMonthlyPremium: false,
};

const formSlice = createSlice({
    name: 'form',
    initialState: initialCounterState,
    reducers: {
        setRequireMonthlyPremium(state, action) {
            state.requireMonthlyPremium = action.payload;
        },

        toggleOption(state) {
            state.showOption = !state.showOption;
        },

        toggleSubMotivation(state) {
            state.showSubMotivation = !state.showSubMotivation;
        },
        hideExtra(state, action) {
            state.showExtra = action.payload;
        },
        hideHospital(state, action) {
            state.showHospital = action.payload;
        },
        setFundList(state, action) {
            state.fundList = action.payload;
        },
        setQuery(state, action) {
            state.query = action.payload;
        },
        setZohoId(state, action) {
            state.zoho_number = action.payload;
        },
        setPriceFilter(state, action) {
            state.queryForm.priceFilter = action.payload;
        },
        setFormPolicies(state, action) {
            state.formPolicies = action.payload;
        },
        setFormExtraPolicies(state, action) {
            state.formExtraPolicies = action.payload;
        },
        setCorporateForm(state, action) {
            state.corporateForm = action.payload;
        },
        clearHospitalAncillaryProducts(state, action) {
            state.currentFundPolicy = null;
            state.currentFundExtraPolicy = null;
        },
        clearAncillaryProducts(state, action) {
            state.currentFundExtraPolicy = null;
        },
        setHospitalTiers(state, action) {
            state.hospitalTiers = action.payload;
        },
        setFunds(state, action) {
            state.funds = action.payload;
        },
        addToQueryForm(state, action) {
            const currentType = action.payload.type;

            switch (currentType !== null) {
                case currentType === 'medicare_eligibility':
                    state.queryForm.medicareEligibility = action.payload.data;
                    break;
                case currentType === 'has_current_fund':
                    state.queryForm.has_current_fund = action.payload.data;
                    break;
                case currentType === 'motivation':
                    state.queryForm.motivation = action.payload.data;
                    break;
                case currentType === 'subMotivation':
                    state.queryForm.subMotivation = action.payload.data;
                    break;
                case currentType === 'membership':
                    state.queryForm.membership = action.payload.data;
                    break;
                case currentType === 'state':
                    state.queryForm.state = action.payload.data;
                    break;
                case currentType === 'coverType':
                    state.queryForm.coverType = action.payload.data;
                    break;
                case currentType === 'excess':
                    state.queryForm.excess = action.payload.data;
                    break;
                case currentType === 'primaryService':
                    state.queryForm.primaryService = action.payload.data;
                    break;
                case currentType === 'preferPrimary':
                    state.queryForm.hospitalProducts = action.payload.data;
                    break;
                case currentType === 'preferExtra':
                    state.queryForm.ancillaryProducts = action.payload.data;
                    break;
                case currentType === 'currentFund':
                    state.queryForm.currentFund = action.payload.data;
                    break;
                case currentType === 'currentFundState':
                    state.queryForm.currentFundState = action.payload.data;
                    break;
                case currentType === 'currentFundMembership':
                    state.queryForm.currentFundMembership = action.payload.data;
                    break;
                case currentType === 'currentFundPolicy':
                    state.queryForm.currentFundPolicy = action.payload.data;
                    break;
                case currentType === 'currentFundExtraPolicy':
                    state.queryForm.currentFundExtraPolicy = action.payload.data;
                    break;
                case currentType === 'currentPremium':
                    state.queryForm.currentPremium = action.payload.data;
                    break;
                case currentType === 'dob':
                    state.queryForm.dob = action.payload.data;
                    break;
                case currentType === 'partner_dob':
                    state.queryForm.partnerDob = action.payload.data;
                    break;
                case currentType === 'has_hold_insurance':
                    state.queryForm.hasHoldInsurance = action.payload.data;
                    break;
                case currentType === 'lhc_age':
                    state.queryForm.lhcAge = action.payload.data;
                    break;
                case currentType === 'lhc_age_partner':
                    state.queryForm.lhcAgePartner = action.payload.data;
                    break;
                case currentType === 'apply_rebate':
                    state.queryForm.applyRebate = action.payload.data;
                    break;
                case currentType === 'rebate':
                    state.queryForm.rebate = action.payload.data;
                    break;
                case currentType === 'youthDiscount':
                    state.queryForm.youthDiscount = action.payload.data;
                    break;
                case currentType === 'partnerYouthDiscount':
                    state.queryForm.partnerYouthDiscount = action.payload.data;
                    break;
                case currentType === 'discount':
                    state.queryForm.discount = action.payload.data;
                    break;
                default:
                    // do nothing
                    console.log('Not match');
            }
        },
    },
});

export const formActions = formSlice.actions;

export default formSlice.reducer;
