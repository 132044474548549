import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = `${process.env.REACT_APP_API_HOST}/api`;

const createRequest = url => ({
    url,
    headers: { 'Content-Type': 'application/json' },
    method: 'GET',
});

export const servicesApi = createApi({
    reducerPath: 'servicesApi',
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: builder => ({
        getServices: builder.query({
            query: () => createRequest('/services'),
        }),
    }),
});

export const { useGetServicesQuery } = servicesApi;
