import { createSlice } from '@reduxjs/toolkit';

const initialUserState = {
    isLoggedIn: 'success',
    user: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,

    reducers: {
        login: (state, action) => {
            state.isLoggedIn = 'success';
        },
        logout: (state, action) => {
            state.isLoggedIn = 'no-user';
            state.user = null;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
    },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
