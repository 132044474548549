import { configureStore } from '@reduxjs/toolkit';
import formReducer from './form';
import applicantReducer from './applicant';
import compareReducer from './compare';
import quoteReducer from './quote';
import previousFundReducer from './previousfund';
import themeReducer from './theme';
import userReducer from './user';
import { servicesApi } from '../lib/servicesApi';
import { fundsApi } from '../lib/fundsApi';
import { applicantApi } from '../lib/applicantApi';

const store = configureStore({
    reducer: {
        form: formReducer,
        applicant: applicantReducer,
        compare: compareReducer,
        quote: quoteReducer,
        previousfund: previousFundReducer,
        theme: themeReducer,
        user: userReducer,
        [servicesApi.reducerPath]: servicesApi.reducer,
        [fundsApi.reducerPath]: fundsApi.reducer,
        [applicantApi.reducerPath]: applicantApi.reducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;
