import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = `${process.env.REACT_APP_API_HOST}/api`;

const createRequest = (url, body) => ({
    url,
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body,
});

export const applicantApi = createApi({
    reducerPath: 'applicantApi',
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: builder => ({
        getApplicant: builder.query({
            query: ({ opportunity_id, corporate_id, type }) =>
                createRequest(`zoho/${opportunity_id}`, { corporate_id, type }),
        }),
    }),
});

export const { useGetApplicantQuery } = applicantApi;
