export async function getFormApplicant(opportunityId) {
    const url = `${process.env.REACT_APP_API_HOST}/zoho/` + opportunityId;

    const response = await fetch(url, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            corporate_id: process.env.REACT_APP_CORPORATE_ID,
            type: 'get_lead',
        }),
    });

    return await response.json();
}

export async function getPreviousFund(hospitalId) {
    const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/prices/` +
            hospitalId +
            `/?corporate_id=${process.env.REACT_APP_CORPORATE_ID}`
    );

    return await response.json();
}

export async function getPreviousProducts(items) {
    const { fund_code, state, membership } = items;

    const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/previous-fund-products/?fund=${fund_code}&state=${state}&membership=${membership}`
    );
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch products.');
    }

    return data;
}

export async function getPreviousFundPrice(price_id) {
    const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/prices/${price_id}?corporate_id=${process.env.REACT_APP_CORPORATE_ID}`
    );
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch price.');
    }

    return data;
}

export async function getZohoApplicant(opportunity_id) {
    const url = `${process.env.REACT_APP_API_HOST}/zoho/` + opportunity_id;

    const response = await fetch(url, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            corporate_id: process.env.REACT_APP_CORPORATE_ID,
            type: 'get_lead',
        }),
    });

    if (!response.ok) {
        console.log('Error');
    }

    const data = await response.json();

    return data;
}

export async function sendToZoho(opportunity_id, applicant, price) {
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            type: 'send_to_zoho',
            applicant: applicant,
            is_quote: true,
            suppress_opportunity_note: false,
            product: price,
            direct_debit_discount: false,
        }),
    };

    const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/zoho/${opportunity_id}?corporate_id=${process.env.REACT_APP_CORPORATE_ID}`,
        requestOptions
    );

    if (!response.ok) {
        console.log('Error');
    }

    const data = await response.json();

    return data;
}

export async function sendNeedAnalysisToZoho(opportunity_id, applicant) {
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ type: 'send_to_zoho', applicant: applicant, is_compare: true }),
    };

    const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/zoho/${opportunity_id}?corporate_id=${process.env.REACT_APP_CORPORATE_ID}`,
        requestOptions
    );

    return await response.json();
}

export async function sendServicesToZoho(opportunity_id, services) {
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(services),
    };

    const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/zoho/${opportunity_id}/services?corporate_id=${process.env.REACT_APP_CORPORATE_ID}`,
        requestOptions
    );

    return await response.json();
}
