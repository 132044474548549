import { createSlice } from '@reduxjs/toolkit';

const initialCompareState = {
    allProducts: [],
    showProducts: {},
    itemToCompare: [],
    packageApplicant: null,
    previousHospitalProduct: null,
    previousAncillaryProduct: null,
    offset: 0,
};

const compareSlice = createSlice({
    name: 'compare',
    initialState: initialCompareState,

    reducers: {
        setAllProducts(state, action) {
            state.allProducts = action.payload;
        },
        setItemToCompare(state, action) {
            state.itemToCompare.push(action.payload);
        },
        setPreviousFund(state, action) {
            state.previousHospitalProduct = action.payload;
        },
        setPreviousAncillaryProduct(state, action) {
            state.previousAncillaryProduct = action.payload;
        },
        setPackageApplicant(state, action) {
            state.packageApplicant = action.payload;
        },
        removeItemToCompare(state, action) {
            state.itemToCompare = state.itemToCompare.filter(item => item.id !== action.payload);
        },
        removeAllItemToCompare(state) {
            state.itemToCompare = [];
        },
        setPageOffset(state, action) {
            state.offset = action.payload;
        },
        increasePageOffset(state) {
            state.offset += 1;
        },
        decreasePageOffset(state) {
            state.offset -= 1;
        },
    },
});

export const compareActions = compareSlice.actions;

export default compareSlice.reducer;
