import { Button, Card, Divider, Grid, Header, Image } from 'semantic-ui-react';

const FundCard = props => {
    return (
        <Card fluid>
            <Header style={{ padding: '1rem', backgroundColor: 'teal', color: 'white' }}>
                {' '}
                {props.title}{' '}
            </Header>

            <Card.Content>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <Image
                                src="https://1000logos.net/wp-content/uploads/2020/09/AIA-Logo.png"
                                size="medium"
                            />
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <p style={{ fontSize: '1rem' }}>
                                {' '}
                                <b> AIA Group Limited,[2] </b> known as AIA (友邦保險), is an
                                American-founded Hong Kong multinational insurance and finance
                                corporation. It is the largest public listed life insurance and
                                securities group in Asia-Pacific. It offers insurance and financial
                                services, writing life insurance for individuals and businesses, as
                                well as accident and health insurance, and offers retirement
                                planning, and wealth management services, variable contracts,
                                investments and securities.
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card.Content>
        </Card>
    );
};

export default FundCard;
