import _ from 'lodash';

export const build_send_to_zoho_applicant = (
    queryForm,
    applicant,
    selectedHospital,
    selectedAncillary
) => {
    // Clone the Applicant
    const deepApplicant = _.cloneDeep(applicant);
    const personalInfo = _.clone(applicant.personal);
    const newApplicant = _.omit(deepApplicant, [
        'policy',
        'primary_address',
        'personal',
        'partner',
    ]);

    // We need to format the Date for DOB and Partner DOB

    let reverseDob = null;
    let reverseNewPartnerDob = null;

    if (queryForm.dob) {
        reverseDob = queryForm.dob.split('-').reverse().join('-');
    }
    if (queryForm.partnerDob) {
        reverseNewPartnerDob = queryForm.partnerDob.split('-').reverse().join('-');
    }

    const personal = {
        dob: reverseDob,
        first_name: personalInfo.first_name,
        gender: personalInfo.gender,
        last_name: personalInfo.last_name,
        previous_fund: {
            ancillary_product: queryForm.has_current_fund ? selectedAncillary : null,
            fund_id: queryForm.has_current_fund ? queryForm.currentFund : null,
            fund_name: queryForm.has_current_fund ? queryForm.currentFundState : null,
            has_previous_fund: queryForm.has_current_fund ? queryForm.has_current_fund : null,
            hospital_product: queryForm.has_current_fund ? selectedHospital : null,
            membership: queryForm.has_current_fund ? queryForm.currentFundMembership : null,
            state: queryForm.has_current_fund ? queryForm.currentFundState : null,
        },
    };

    // Build a policy first
    const policy = {
        cover_type: queryForm.coverType,
        federal_rebate: queryForm.applyRebate,
        lhc_age: queryForm.lhcAge ? queryForm.lhcAge : 30,
        lhc_age_partner: queryForm.lhcAgePartner ? queryForm.lhcAgePartner : 30,
        medicare: {
            number: undefined,
            first_name: undefined,
            last_name: undefined,
            eligibility: queryForm.medicareEligibility === true ? 'Yes' : 'No',
        },
        membership_type: queryForm.membership,
        // Todo : Compute Motivation age later
        motivation: null,
        motivation2: null,
        primary_youth_discount_age: queryForm.youthDiscount,
        partner_youth_discount_age: queryForm.partnerYouthDiscount,
        primary_service_need: queryForm.primaryService,
        rebate_tier: queryForm.rebate,
        has_continuous_cover: queryForm.hasHoldInsurance,
        // Todo: Find out what is Tier Index and Tier Age Index??
    };

    // Build primary address second
    const primary_address = {
        state: queryForm.state,
    };

    // Build partner next

    const partner = {
        dob: reverseNewPartnerDob,
    };

    return { ...newApplicant, policy, primary_address, partner, personal };
};

export const build_query = (
    queryForm,
    appCtx,
    hospital_classifications,
    funds,
    hospital_product,
    ancillary_product,
    offset = 0
) => {
    let reverseDob = null;
    let reverseNewPartnerDob = null;

    if (queryForm.dob) {
        reverseDob = queryForm.dob.split('-').reverse().join('-');
    }
    if (queryForm.partnerDob) {
        reverseNewPartnerDob = queryForm.partnerDob.split('-').reverse().join('-');
    }

    const query = {
        corporate_id: process.env.REACT_APP_CORPORATE_ID,
        // "filters": filters,
        application: {
            personal: {
                dob: reverseDob,
                previous_fund: {
                    has_previous_fund: queryForm.has_current_fund,
                    hospital_product: queryForm.has_current_fund ? hospital_product : null,
                    ancillary_product: queryForm.has_current_fund ? ancillary_product : null,
                },
            },
            partner: {
                dob: reverseNewPartnerDob,
            },
            policy: {
                lhc_age: queryForm.lhcAge ? queryForm.lhcAge : 30,
                lhc_age_partner: queryForm.lhcAgePartner ? queryForm.lhcAgePartner : 30,
                cover_type: queryForm.coverType,
                membership_type: queryForm.membership,
                federal_rebate: queryForm.applyRebate,
                rebate_tier: queryForm.rebate,
                primary_youth_discount_age: queryForm.youthDiscount,
                partner_youth_discount_age: queryForm.partnerYouthDiscount,
                discount_code: queryForm.discount,
                primary_service_need: queryForm.primaryService,
                has_continuous_cover: queryForm.hasHoldInsurance,
                rebate_tier_age_index: 0,
                rebate_tier_matrix: '0,0',
            },
            primary_address: {
                state: queryForm.state,
            },
        },
        filters: {
            display_order: queryForm.priceFilter,
            non_panel_funds: [],
            page_size: 15,
            offset: offset,
            include_future_pricing: true,
            excess_range: queryForm.excess,
            discount_code: queryForm.discount,
            discount_code_choices: appCtx.formData.hasOwnProperty('discount_code_choices')
                ? appCtx.formData.discount_code_choices
                : null,
            referral_fund: false,
            show_comparison_policy: null,
            show_current_policy: true,
            show_products_from_current_fund: true,
            hospital_classifications: hospital_classifications,
            funds: funds,
        },
        services: {
            hospital: queryForm.hospitalProducts,
            ancillary: queryForm.ancillaryProducts,
        },
    };

    if (queryForm.has_current_fund) {
        query.current_fund_id = queryForm.currentFund
    }
    return query;
};

export const build_hospital_classification = (currentHospitalTiers, tiers) => {

    if (currentHospitalTiers) {
        if (currentHospitalTiers.length === 0) {
            return tiers.map(item => {
                return {
                    code: item[0],
                    name: item[1],
                    selected: true,
                };
            });
        }

        return tiers.map(item => {
            if (currentHospitalTiers.includes(item[0])) {
                return {
                    code: item[0],
                    name: item[1],
                    selected: true,
                };
            } else {
                return {
                    code: item[0],
                    name: item[1],
                    selected: false,
                };
            }
        });
    } else {
        return tiers.map(item => {
            return {
                code: item[0],
                name: item[1],
                selected: false,
            };
        });
    }
};

export const build_services = queryForm => {
    const services = {
        hospital: queryForm.hospitalProducts,
        ancillary: queryForm.ancillaryProducts,
    };

    return services;
};

export const build_post_method = (query, method) => {
    const requestOptions = {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(query),
    };

    return requestOptions;
};

export const sanitizeData = (queryForm, applicant, opportunity_id, sendEmail, sendSms) => {
    const sanitizedData = build_send_to_zoho_applicant(queryForm, applicant, null, null);

    // Check current products. When user clears a product field that previously contained a value,
    // the model gets set to the value of "" (empty string). Backend expects an object|null, so
    // convert it here.
    const hasEmptyCurrentHospital =
        _.get(sanitizedData, 'personal.previous_fund.hospital_product', null) === '';
    const hasEmptyCurrentAncillary =
        _.get(sanitizedData, 'personal.previous_fund.ancillary_product', null) === '';
    if (hasEmptyCurrentHospital) {
        _.set(sanitizedData, 'personal.previous_fund.hospital_product', null);
    }
    if (hasEmptyCurrentAncillary) {
        _.set(sanitizedData, 'personal.previous_fund.ancillary_product', null);
    }

    //omit zoho and reconstruct it

    _.omit(sanitizedData, 'zoho');

    const zohoDetails = {
        expected_yearly_savings: 0,
        improved_benefit_1: '',
        improved_benefit_2: '',
        improved_benefit_3: '',
        send_email: sendEmail,
        send_sms: sendSms,
    };

    const finalData = { ...sanitizedData, zoho: zohoDetails, zoho_id: opportunity_id };

    return finalData;
};
