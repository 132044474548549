import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFormApplicant, getPreviousFund, sendNeedAnalysisToZoho } from '../lib/api';
import { quoteActions } from './quote';
import { build_send_to_zoho_applicant } from '../utility/query_utility';

const initialApplicantState = {
    applicant: null,
    hasApplicant: false,
    hospital_product: null,
    ancillary_product: null,
    previous_fund_membership: null,
    quote_to_send: null,
    isLoadingPreviousFundDetails: null,
    isDispatchingPolicyDetails: 'pending',
    isDispatchingAncillaryPolicyDetails: 'pending',
    isLoadingApplicant: null,
    isLoadingSendingNeedAnalysis: null,
    isSendingServicesToZoho: null,
};

// Use this sole only use effect load previous fund
export const getApplicantPreviousFund = createAsyncThunk(
    'applicant/getPreviousFund',
    async ({ hospitalId }, thunkAPI) => {
        return getPreviousFund(hospitalId);
    }
);

export const getApplicant = createAsyncThunk(
    'applicant/getApplicant',
    async (opportunityId, thunkAPI) => {
        return getFormApplicant(opportunityId);
    }
);

export const sendNeedAnalysis = createAsyncThunk(
    'applicant/sendNeedAnalysis',
    async ({ opportunityId, applicant }, thunkAPI) => {
        return sendNeedAnalysisToZoho(opportunityId, applicant);
    }
);

export const sendServices = createAsyncThunk(
    'applicant/sendServiceToZoho',
    async ({ opportunityId, services }, thunkAPI) => {
        return await fetch(
            `${process.env.REACT_APP_API_HOST}/zoho/${opportunityId}/services?corporate_id=${process.env.REACT_APP_CORPORATE_ID}`,
            {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ...services }),
            }
        );
    }
);

export const sendApplicantToZoho = createAsyncThunk(
    'applicant/sendApplicantToZoho',
    async ({ opportunityId, services, hospitalId, applicant, queryForm }, thunkAPI) => {
        let priceData;

        if (hospitalId) {
            priceData = thunkAPI.dispatch(getApplicantPreviousFund({ hospitalId }));
        } else {
            priceData = null;
        }

        // build Query for sendNeedAnalysis
        const prepApplication = build_send_to_zoho_applicant(
            queryForm,
            applicant,
            priceData.payload
        );

        console.log(prepApplication);

        thunkAPI.dispatch(sendNeedAnalysis({ opportunityId, applicant: prepApplication }));

        return await thunkAPI.dispatch(sendServices({ opportunityId, services }));
    }
);

const applicantSlice = createSlice({
    name: 'applicant',
    initialState: initialApplicantState,

    reducers: {
        setApplicant(state, action) {
            state.applicant = action.payload;
        },

        setPreviousFundDetails(state, action) {
            state.previous_fund_details = action.payload;
        },
        setQuoteToSend(state, action) {
            state.quote_to_send = action.payload;
        },
        setLoadingApplicant(state, action) {
            state.isLoadingApplicant = 'success';
        },
        clearLoadingApplicant(state, action) {
            state.isLoadingApplicant = 'pending';
        },
        setLoadingPreviousFund(state, action) {
            state.isLoadingPreviousFundDetails = 'not-exist';
        },
        setIsDispatchingPolicyDetails(state, action) {
            state.isDispatchingPolicyDetails = action.payload;
        },
        setIsDispatchingAncillaryPolicyDetails(state, action) {
            state.isDispatchingAncillaryPolicyDetails = action.payload;
        },
    },
    extraReducers: builder => {
        // Previous Fund Load
        builder.addCase(getApplicantPreviousFund.pending, (state, action) => {
            state.isLoadingPreviousFundDetails = 'pending';
        });

        builder.addCase(getApplicantPreviousFund.fulfilled, (state, action) => {
            let productStatus;
            if (action.payload.price.product_status === 0) {
                productStatus = 'Open';
            } else {
                productStatus = 'Close';
            }

            if (action.payload.price.type === 0) {
                state.previous_fund_membership = action.payload.price.membership_type;

                state.hospital_product = {
                    id: action.payload.price.id,
                    name: action.payload.price.name,
                    excess: action.payload.price.excess,
                    monthly_premium: action.payload.price.sis_monthly_premium,
                    product_status: productStatus,
                    fund_code: action.payload.fund.code,
                    type: action.payload.price.type,
                };
            } else if (action.payload.price.type === 1) {
                state.previous_fund_membership = action.payload.price.membership_type;

                state.ancillary_product = {
                    id: action.payload.price.id,
                    name: action.payload.price.name,
                    excess: action.payload.price.excess,
                    monthly_premium: action.payload.price.sis_monthly_premium,
                    product_status: productStatus,
                    fund_code: action.payload.fund.code,
                    type: action.payload.price.type,
                };
            } else if (action.payload.price.type === 2) {
                state.previous_fund_membership = action.payload.price.membership_type;

                state.hospital_product = {
                    id: action.payload.price.id,
                    name: action.payload.price.name,
                    excess: action.payload.price.excess,
                    monthly_premium: action.payload.price.sis_monthly_premium,
                    product_status: productStatus,
                    fund_code: action.payload.fund.code,
                    type: action.payload.price.type,
                };

                state.ancillary_product = null;
            }

            state.isLoadingPreviousFundDetails = 'success';
        });

        builder.addCase(getApplicantPreviousFund.rejected, (state, action) => {
            state.isLoadingPreviousFundDetails = 'failed';
        });

        // Applicant Load
        builder.addCase(getApplicant.pending, (state, action) => {
            state.isLoadingApplicant = 'pending';
        });

        builder.addCase(getApplicant.fulfilled, (state, action) => {
            // Check if it is really fulfilled,
            if (!action.payload.valid) {
                state.applicant = null;
                state.hasApplicant = false;
                state.isLoadingApplicant = 'success';

                // This would mean you cannot load previous fund as well
                state.isLoadingPreviousFundDetails = 'success';
            } else {
                state.applicant = action.payload;
                state.hasApplicant = true;
                state.isLoadingApplicant = 'success';
            }
        });

        builder.addCase(getApplicant.rejected, (state, action) => {
            state.isLoadingApplicant = 'failed';
        });
        // Sending Need Analysis
        builder.addCase(sendNeedAnalysis.pending, (state, action) => {
            state.isLoadingSendingNeedAnalysis = 'pending';
        });
        builder.addCase(sendNeedAnalysis.fulfilled, (state, action) => {
            state.isLoadingSendingNeedAnalysis = 'success';
        });
        builder.addCase(sendNeedAnalysis.rejected, (state, action) => {
            state.isLoadingSendingNeedAnalysis = 'failed';
        });

        // Sending to Zoho
        builder.addCase(sendServices.pending, (state, action) => {
            state.isSendingServicesToZoho = 'pending';
        });
        builder.addCase(sendServices.fulfilled, (state, action) => {
            state.isSendingServicesToZoho = 'success';
        });
        builder.addCase(sendServices.rejected, (state, action) => {
            state.isSendingServicesToZoho = 'failed';
        });
    },
});

export const applicantActions = applicantSlice.actions;

export default applicantSlice.reducer;
