import { useState } from 'react';

import AppContext from './app-context';

const AppContextProvider = props => {
    const [queryParams, setQueryParams] = useState([]);

    const [formData, setFormData] = useState([]);

    const [services, setServices] = useState([]);

    const addQueryHandler = q_array => {
        setQueryParams(q_array);
    };

    const addFormDataHandler = form_array => {
        setFormData(form_array);
    };

    const addServicesHandler = service_object => {
        setServices(service_object);
    };

    const appContext = {
        items: queryParams,
        formData: formData,
        services: services,
        addServicesHandler: addServicesHandler,
        addQueryHandler: addQueryHandler,
        addFormDataHandler: addFormDataHandler,
    };

    return <AppContext.Provider value={appContext}>{props.children}</AppContext.Provider>;
};

export default AppContextProvider;
