import QuoteCard from '../components/MainComponents/QuoteComponents/QuoteCard';
import { Grid, Container, GridRow } from 'semantic-ui-react';
import QuoteDetailCard from '../components/MainComponents/QuoteComponents/QuoteDetailCard';
import FundCard from '../components/MainComponents/QuoteComponents/FundCard';

const PRIMARY_SERVICES_EXAMPLES = [
    'Dental Surgery',
    'Breast Surgery',
    'Chemotherapy',
    'Gynaecology',
    'Bone Therapy',
    'Digestive System',
    'Joint Replacements',
];

const EXTRA_SERVICES_EXAMPLES = [
    'Dental General',
    'Optical',
    'Dental Major',
    'Orthodontic',
    'Endodontic',
    'Physiotherapy',
];

const Quote = () => {
    return (
        <Container>
            <Grid stackable columns={3}>
                <GridRow>
                    <Grid.Column width={16}>
                        <FundCard title="About your Fund"> </FundCard>
                    </Grid.Column>
                </GridRow>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <QuoteCard title="Your details" type="detail">
                            {' '}
                        </QuoteCard>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <QuoteCard title="Cover type" type="cover">
                            {' '}
                        </QuoteCard>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <QuoteCard title="Join" type="join">
                            {' '}
                        </QuoteCard>
                    </Grid.Column>
                </Grid.Row>
                <GridRow>
                    <Grid.Column width={8}>
                        <QuoteDetailCard
                            title="Hospital Products"
                            items={PRIMARY_SERVICES_EXAMPLES}
                        >
                            {' '}
                        </QuoteDetailCard>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <QuoteDetailCard title="Extra" items={EXTRA_SERVICES_EXAMPLES}>
                            {' '}
                        </QuoteDetailCard>
                    </Grid.Column>
                </GridRow>
            </Grid>
        </Container>
    );
};

export default Quote;
