import './App.css';
import React, { Suspense, useEffect } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Switch, Route, useLocation, Redirect, useHistory } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import Nav from './components/UniversalComponents/NavigationHeader';
import TopHeader from './components/UniversalComponents/TopHeader';
import AppContextProvider from './store/AppContextProvider';
import Quote from './pages/Quote';
import PageNotFound from './pages/PageNotFound';
import { useDispatch, useSelector } from 'react-redux';
import awsconfig from './aws-exports';
import Amplify from 'aws-amplify';
import { themeActions } from './store/theme';
import { Helmet } from 'react-helmet';
import { ClipLoader } from 'react-spinners';
import { formActions } from './store/form';

//Histories
// export const appHistory = createBrowserHistory();

// Protected Route
// const ProtectedRoute = ({ component, ...args }) => (
//     <Route component={withAuthenticationRequired(component)} {...args} />
// );

// const onRedirectCallback = (appState) => {
//     // Use the router's history module to replace the url
//     appHistory.replace(appState?.returnTo || window.location.pathname);
// };

// Configure AWS
Amplify.configure(awsconfig);

// This array contain paths that require component <Nav />.
const paths = ['/comparator-step-1', '/comparator-step-2'];

// Lazy Load
const ComparatorStepOne = React.lazy(() => import('./pages/ComparatorStepOne'));
const ComparatorStepTwo = React.lazy(() => import('./pages/ComparatorStepTwo'));

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const App = () => {
    // AuthenticationComponents
    const isLoggedIn = useSelector(state => state.user.isLoggedIn);

    // Routers
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();

    // Set up themes
    dispatch(
        themeActions.setHeaderColour(
            process.env.REACT_APP_HEADER_THEME ? process.env.REACT_APP_HEADER_THEME : null
        )
    );
    dispatch(
        themeActions.setButtonColour(
            process.env.REACT_APP_BUTTON_THEME ? process.env.REACT_APP_BUTTON_THEME : null
        )
    );
    dispatch(
        themeActions.setLabelColour(
            process.env.REACT_APP_LABEL_THEME ? process.env.REACT_APP_LABEL_THEME : null
        )
    );
    dispatch(
        themeActions.setFilterLabelColour(
            process.env.REACT_APP_FILTER_LABEL_THEME
                ? process.env.REACT_APP_FILTER_LABEL_THEME
                : null
        )
    );
    dispatch(
        themeActions.setMenuFontColour(
            process.env.REACT_APP_MENU_FONT_COLOUR ? process.env.REACT_APP_MENU_FONT_COLOUR : null
        )
    );
    dispatch(
        themeActions.setMenuFontWeight(
            process.env.REACT_APP_MENU_FONT_WEIGHT ? process.env.REACT_APP_MENU_FONT_WEIGHT : null
        )
    );
    dispatch(
        themeActions.setHeaderFontColour(
            process.env.REACT_APP_HEADER_FONT_COLOUR
                ? process.env.REACT_APP_HEADER_FONT_COLOUR
                : null
        )
    );
    dispatch(
        themeActions.setMenuFontSize(
            process.env.REACT_APP_MENU_FONT_SIZE ? process.env.REACT_APP_MENU_FONT_SIZE : '1rem'
        )
    );
    dispatch(
        formActions.setRequireMonthlyPremium(
            process.env.REACT_APP_REQUIRED_MONTHLY_PREMIUM === 'YES'
        )
    );

    useEffect(() => {
        let isMounted = true;

        if (
            process.env.REACT_APP_CORPORATE_ID === null ||
            process.env.REACT_APP_CORPORATE_ID === undefined
        ) {
            history.push('/no-corporate-id');
        }

        // AssessLoggedInState();

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {' '}
                    {process.env.REACT_APP_TITLE
                        ? process.env.REACT_APP_TITLE
                        : 'ItsMy Comparator Development'}
                </title>
                <meta
                    name="description"
                    content="Development website for ItsMy New Comparator Frontend"
                />
            </Helmet>
            <AppContextProvider>
                <TopHeader />
                {paths.includes(location.pathname) && (
                    <React.Fragment>
                        <Nav />
                    </React.Fragment>
                )}
                <Suspense fallback={<Loader />}>
                    <Switch>
                        {isLoggedIn === 'success' && (
                            <React.Fragment>
                                <Route path="/" exact>
                                    <Redirect to="/comparator-step-1" />
                                </Route>

                                <Route
                                    path="/comparator-step-1"
                                    exact
                                    component={ComparatorStepOne}
                                />
                                <Route
                                    path="/comparator-step-2"
                                    exact
                                    component={ComparatorStepTwo}
                                />

                                <Route path="/get-quote" exact>
                                    <Quote />
                                </Route>
                            </React.Fragment>
                        )}
                        {isLoggedIn === 'pending' && (
                            <React.Fragment>
                                <Route path="*">
                                    <React.Fragment>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '80vh',
                                            }}
                                        >
                                            <ClipLoader color="#FFBF00" loading={true} size={250} />
                                            <h2> Validating your credentials...</h2>
                                        </div>
                                    </React.Fragment>
                                </Route>
                            </React.Fragment>
                        )}
                        {isLoggedIn === 'no-user' && (
                            <React.Fragment>
                                <Route path="*">
                                    <PageNotFound text="Cannot Login" />
                                </Route>
                            </React.Fragment>
                        )}

                        <Route path="*">
                            <PageNotFound text="Page not found" />
                        </Route>
                    </Switch>
                </Suspense>
            </AppContextProvider>
        </React.Fragment>
    );
};

export default App;
