import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPreviousFund } from '../lib/api';

const initialPreviousFundState = {
    fund_name: null,
    has_previous_fund: false,
    fund_monthly_payment: null,
    fund_id: null,
    membership: null,
    state: null,
    hospital_product: null,
    ancillary_product: null,
    isPreparingPreviousFund: null,
    logo: null,
};

// Async Thunk function
export const prepareApplicantPreviousFund = createAsyncThunk(
    'applicant/preparePreviousFund',
    async ({ hospitalId }, thunkAPI) => {
        return getPreviousFund(hospitalId);
    }
);

const previousFundSlice = createSlice({
    name: 'previousfund',
    initialState: initialPreviousFundState,
    reducers: {
        initialisePreviousFund: (state, action) => {
            if (action.payload.has_previous_fund) {
                state.fund_name = action.payload.fund_name;
                state.fund_id = action.payload.fund_id;
                state.fund_monthly_payment = action.payload.fund_monthly_payment;
                state.has_previous_fund = action.payload.has_previous_fund;
                state.hospital_product = action.payload.hospital_product
                    ? action.payload.hospital_product
                    : null;
                state.ancillary_product = action.payload.ancillary_product
                    ? action.payload.ancillary_product
                    : null;
            }
        },
        clearHospitalAncillaryProducts: (state, action) => {
            state.hospital_product = null;
            state.ancillary_product = null;
        },
        setHospitalOther: (state, action) => {
            state.hospital_product = {
                name: 'Other (no matching product found)',
                type: 0,
                excess: 0,
                monthly_premium: 0,
            };
        },
        setAncillaryOther: (state, action) => {
            state.ancillary_product = {
                name: 'Other (no matching product found)',
                type: 1,
                excess: 0,
                monthly_premium: 0,
            };
        },
        setLoadingPreviousFund(state, action) {
            state.isPreparingPreviousFund = 'not-exist';
        },

        setFundLogo(state, action) {
            state.logo = action.payload;
        },

        setHospital: (state, action) => {
            let productStatus;
            if (action.payload.price.product_status === 0) {
                productStatus = 'Open';
            } else {
                productStatus = 'Close';
            }

            state.hospital_product = {
                id: action.payload.price.id,
                name: action.payload.price.name,
                excess: action.payload.price.excess,
                monthly_premium: action.payload.price.sis_monthly_premium,
                product_status: productStatus,
                fund_code: action.payload.fund.code,
                type: action.payload.price.type,
                logo: action.payload.fund.logo,
            };

            if (action.payload.price.type === 2) {
                state.ancillary_product = null;
            }
        },
        setAncillary: (state, action) => {
            let productStatus;
            if (action.payload.price.product_status === 0) {
                productStatus = 'Open';
            } else {
                productStatus = 'Close';
            }

            state.ancillary_product = {
                id: action.payload.price.id,
                name: action.payload.price.name,
                excess: action.payload.price.excess,
                monthly_premium: action.payload.price.sis_monthly_premium,
                product_status: productStatus,
                fund_code: action.payload.fund.code,
                type: action.payload.price.type,
                logo: action.payload.fund.logo,
            };
        },
    },
    extraReducers: builder => {
        // Previous Fund Load using Dropdown
        builder.addCase(prepareApplicantPreviousFund.pending, (state, action) => {
            state.isPreparingPreviousFund = 'pending';
        });

        builder.addCase(prepareApplicantPreviousFund.fulfilled, (state, action) => {
            // Prepare the fund
            console.log(action.payload);

            let productStatus;
            if (action.payload.price.product_status === 0) {
                productStatus = 'Open';
            } else {
                productStatus = 'Close';
            }

            if (action.payload.price.type === 0) {
                state.membership = action.payload.price.membership_type;

                state.hospital_product = {
                    id: action.payload.price.id,
                    name: action.payload.price.name,
                    excess: action.payload.price.excess,
                    monthly_premium: action.payload.price.sis_monthly_premium,
                    product_status: productStatus,
                    fund_code: action.payload.fund.code,
                    type: action.payload.price.type,
                    logo: action.payload.fund.logo,
                };
            } else if (action.payload.price.type === 1) {
                state.membership = action.payload.price.membership_type;

                state.ancillary_product = {
                    id: action.payload.price.id,
                    name: action.payload.price.name,
                    excess: action.payload.price.excess,
                    monthly_premium: action.payload.price.sis_monthly_premium,
                    product_status: productStatus,
                    fund_code: action.payload.fund.code,
                    type: action.payload.price.type,
                    logo: action.payload.fund.logo,
                };
            } else if (action.payload.price.type === 2) {
                state.membership = action.payload.price.membership_type;

                state.hospital_product = {
                    id: action.payload.price.id,
                    name: action.payload.price.name,
                    excess: action.payload.price.excess,
                    monthly_premium: action.payload.price.sis_monthly_premium,
                    product_status: productStatus,
                    fund_code: action.payload.fund.code,
                    type: action.payload.price.type,
                    logo: action.payload.fund.logo,
                };

                state.ancillary_product = null;
            }

            state.isPreparingPreviousFund = 'success';
        });

        builder.addCase(prepareApplicantPreviousFund.rejected, (state, action) => {
            state.isPreparingPreviousFund = 'failed';
        });
    },
});

export const previousFundActions = previousFundSlice.actions;

export default previousFundSlice.reducer;
