import { Container, Header, Icon } from 'semantic-ui-react';

const PageNotFound = props => {
    return (
        <Container className="container-page-not-found">
            <div className="page-not-found-inner">
                <div>
                    <Icon size="massive" name="ban" />
                </div>
                <div style={{ marginTop: '1rem' }}>
                    <Header as="h1" color="red">
                        {' '}
                        {props.text}{' '}
                    </Header>
                </div>
            </div>
        </Container>
    );
};

export default PageNotFound;
