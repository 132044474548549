import React from 'react';

const AppContext = React.createContext({
    items: [],
    services: [],
    formData: {},
    addQueryHandler: () => {},
    addServicesHandler: () => {},
    addFormDataHandler: () => {},
});

export default AppContext;
