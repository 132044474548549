import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

//Todo: Make it display stages instead of clickable link
const Nav = () => {
    const headerColour = useSelector(state => state.theme.headerColour);
    const menuFontColour = useSelector(state => state.theme.menuFontColour);
    const menuFontWeight = useSelector(state => state.theme.menuFontWeight);
    const menuFontSize = useSelector(state => state.theme.menuFontSize);

    return (
        <Menu
            style={{
                marginBottom: '0rem',
                marginTop: '0rem',
                borderRadius: 0,
                height: '100%',
                fontSize: menuFontSize,
                color: menuFontColour,
                fontWeight: menuFontWeight,
                backgroundColor: `${headerColour}`,
            }}
            fluid
            widths={3}
            size="massive"
        >
            <Menu.Item
                name="Step 1 - Need Analysis"
                style={{ color: menuFontColour, fontWeight: menuFontWeight }}
                active={window.location.pathname === '/comparator-step-1'}
            />
            <Menu.Item
                name="Step 2 - Comparing"
                style={{ color: menuFontColour, fontWeight: menuFontWeight }}
                active={window.location.pathname === '/comparator-step-2'}
            />
            <Menu.Item
                name="Step 3 - Apply"
                style={{ color: menuFontColour, fontWeight: menuFontWeight }}
                active={window.location.pathname === '/comparator-step-3'}
            />
        </Menu>
    );
};

export default Nav;
