import React from 'react';
import { useState, useEffect } from 'react';
import { Grid, Header, Icon, Image } from 'semantic-ui-react';
import ItsMyLogo from '../../assets/ItsMyLogo.png';
import classes from './TopHeader.module.css';
import Nav from './NavigationHeader';
import { Storage } from 'aws-amplify';

const TopHeader = () => {
    // Declare some responsive function checking
    const [width, setWidth] = useState(window.innerWidth);
    const [header, setHeader] = useState(null);
    const [loadPhoto, setLoadPhoto] = useState(true);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    // Get header photo
    const getHeaderPhoto = async () => {
        // Check if the file exist

        const photoExist = await Storage.list('header.png');

        if (photoExist.length !== 0) {
            const file = await Storage.get('header.png', {
                level: 'public',
            });
            setHeader(file);
        } else {
            console.error('header.png is not available in the backend, please upload the image');
        }

        setLoadPhoto(false);
    };

    useEffect(() => {
        getHeaderPhoto();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return (
        <div className={classes['top-header']}>
            {!loadPhoto && (
                <Image
                    size={width <= 1080 ? 'large' : 'medium'}
                    src={header !== null ? header : ItsMyLogo}
                    centered
                />
            )}
        </div>
    );
};

export default TopHeader;
