import React from 'react';
import { Header, Card, List, Segment, Icon, Grid, Button, Divider } from 'semantic-ui-react';

const QuoteCard = props => {
    return (
        <Card fluid style={{ height: '350px' }}>
            <Header style={{ padding: '1rem', backgroundColor: 'teal', color: 'white' }}>
                {' '}
                {props.title}{' '}
            </Header>

            <Card.Content>
                {props.type === 'detail' && (
                    <React.Fragment>
                        <Header inverted size="small">
                            Single Cover, VIC
                        </Header>
                        <Card.Description>
                            <List>
                                <List.Item>
                                    <List.Header> Age range </List.Header> Under 65
                                </List.Item>
                                <List.Item>
                                    <List.Header> Income </List.Header> $90,000 or less
                                </List.Item>
                                <List.Item>
                                    <List.Header> Rebate Tier </List.Header> 0
                                </List.Item>
                                <List.Item>
                                    <List.Header> Discount </List.Header> 4%
                                </List.Item>
                            </List>
                        </Card.Description>
                    </React.Fragment>
                )}
                {props.type === 'cover' && (
                    <React.Fragment>
                        <Card.Description>
                            <Segment>
                                Standard hospital (Bronze Plus)
                                <Icon style={{ float: 'right' }} name="remove circle" />
                                <Icon style={{ float: 'right' }} name="edit" />
                            </Segment>
                            <Segment>
                                Basic Extras
                                <Icon style={{ float: 'right' }} name="remove circle" />
                                <Icon style={{ float: 'right' }} name="edit" />
                            </Segment>
                        </Card.Description>
                    </React.Fragment>
                )}
                {props.type === 'join' && (
                    <React.Fragment>
                        <Card.Description>
                            <Grid>
                                <Grid.Column width={8}>
                                    <Header> $124.65* </Header>
                                    <label>
                                        {' '}
                                        Hospital Access <Icon name="info circle" />{' '}
                                    </label>
                                    <List>
                                        <List.Item>
                                            <List.Header> Name </List.Header> Phurich Pusapanich
                                        </List.Item>
                                        <List.Item>
                                            <List.Header> State </List.Header> Victoria
                                        </List.Item>
                                        <List.Item>
                                            <List.Header> Policy name </List.Header> Basic Bronze
                                        </List.Item>
                                        <List.Item>
                                            <List.Header> Type </List.Header> Single
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Button.Group vertical>
                                        <Button color="orange"> Join Now </Button>
                                        <Divider />
                                        <Button color="blue"> Email Quote </Button>
                                        <Divider />
                                        <Button color="green"> Send SMS </Button>
                                    </Button.Group>
                                </Grid.Column>
                            </Grid>
                        </Card.Description>
                    </React.Fragment>
                )}
            </Card.Content>
        </Card>
    );
};

export default QuoteCard;
