import { Button, Card, Divider, Header, List, Icon, Popup } from 'semantic-ui-react';
import { useState } from 'react';

const QuoteDetailCard = props => {
    const [toggleDetail, setToggleDetail] = useState(false);

    const expandHandler = () => {
        setToggleDetail(prev => !prev);
    };

    let numSlice = 0;

    if (!toggleDetail) {
        // Assume first that we want to displays 5 items, the result may have less than 5 items.
        numSlice = 5;
    } else {
        numSlice = props.items.length;
    }

    return (
        <Card fluid>
            <Header style={{ padding: '1rem', backgroundColor: 'teal', color: 'white' }}>
                {' '}
                {props.title}{' '}
            </Header>

            <Card.Content>
                <Card.Description>
                    <List>
                        {props.items.slice(0, numSlice).map(item => {
                            return (
                                <List.Item icon="check">
                                    {item}{' '}
                                    <Popup
                                        trigger={<Icon name="info circle" />}
                                        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                        hideOnScroll
                                        wide="very"
                                    />
                                </List.Item>
                            );
                        })}
                    </List>
                </Card.Description>

                <Divider> </Divider>

                <Button basic color="orange" fluid onClick={expandHandler}>
                    {' '}
                    Show more details <Icon name="angle down"> </Icon>
                </Button>
            </Card.Content>
        </Card>
    );
};

export default QuoteDetailCard;
